<template lang="pug">
.authenticated
  //.beta-banner(v-if="isDev")
    | DEV version
  pageError(relative)
  .centered-block(v-if="firstSignup")
    .custom-default-page-block(v-if="onboadingLvl == 0")
      .center-logo
        xProfit.large(:size="30")
      .small-text Dropshipping Profit Tracking
      .spacing 
      .big-text Welcome to Xprofit!
      .big-text-fade To get started, we need to set up a few things...
      .spacing 
      input(type="text", placeholder="example@email.com", class="styled-text-field" :value="profitEmail" @input="updateEmail" @blur="setprofitEmail" @keyup.enter="startXProfit")
      .small-text This is where you will receive your daily profit report.
      .small-text  We <u>do not</u> use this email for advertisement or newsletters.
      .spacing 
      label(for="termsCheckbox")
        input(type="checkbox" id="termsCheckbox" v-model="acceptTerms" class="styled-checkbox")
        |  I accept the 
        a(href="https://app-xprofit.com/terms") Terms and Conditions
        |  and 
        a(href="https://app-xprofit.com/privacy") Privacy Policy
      button.buttonStyle(@click="startXProfit()") Let's go
    .custom-default-page-block-2(v-if="onboadingLvl == 1")
      .progress-bar-container
        .progress-bar-fill 
        .progress-bar-unfill
      .head-space-onboarding
        .head-line-onboarding How to calculate your profits?
      .onboarding-section
        .margin-info-container
          .onboarding-title Transaction Fees
          InformationMarkComponent(placement="left" trigger="hover click" uniqueId="1-onboarding")
            span Transaction costs are fees taken by payment providers from the order revenue.  <br> <br> You will be able to adjust the rates for every used Payment Provider later.
        .entry-s(v-for="(m, i) in prePaymentProvider") 
          .cell-headder
            .entry-name {{ m.name }}
          .cell-headder
            input(type="text", class="styled-text-field-onboarding", placeholder="0.0%" :value="m.percent" @blur="formatValuePercentage(i)" @input="updatePercent($event, i)" @keyup.enter="$event.target.blur()")
          .cell-headder
            input(type="text", class="styled-text-field-onboarding", placeholder="0.0" :value="m.fix" @blur="formatValueFix(i)" @input="updateFix($event, i)" @keyup.enter="$event.target.blur()")
        .small-text-2 You can define more rates later
      .spacing 
      .onboarding-section
        .margin-info-container
          .onboarding-title Product Cost
          InformationMarkComponent(placement="left" trigger="hover click" uniqueId="2-onboarding")
            span Here you can set your Default Product Cost Margin %. This setting will be used to calculate costs for products when their costs are not provided and unknown. <br>  <br>Once you establish the costs for the products in question, the default margin value that was previously applied will be automatically overwritten. You will be able to set Product Costs later. <br> <br> For example, if the average product price is $100 and the average product cost is $40, the recommended default margin is 40%.
        .entry-s
          .cell-headder
            .entry-name-2 What’s your estimated COGS?
          .cell-headder
            input(type="text", class="styled-text-field-onboarding", placeholder="0.0" :value="defaultMargin" @blur="formatDefaultMargin()" @input="updateDefaultMargin($event)" @keyup.enter="$event.target.blur()")
        .small-text-2 You can define exact Product Costs later
      .button-container-align
        button.buttonStyle(@click="startXProfit()") Save & Continue
    .custom-default-page-block-2(v-if="onboadingLvl == 2")
      .progress-bar-container
        .progress-bar-fill 
        .progress-bar-fill
      .head-space-onboarding
        .head-line-onboarding How to calculate your profits?
      .onboarding-section
        .margin-info-container
          .onboarding-title Marketing
          InformationMarkComponent(placement="left" trigger="hover click" uniqueId="3-onboarding")
            span To display marketing costs, we automatically import them from your advertising platform. If your platform is not available, please contact our support.
        LocalLoader(v-if="loadingMarketingOnboarding" small text="Please wait..." align="row")
        .warning-text(v-if="loadingMarketingOnboarding") Please deactivate any ad blocker and ensure that your browser allows pop-ups.
        .general-containe(v-else)
          .x-pro(v-if="!isMobile")
            .x-container-2(@click="loadingMarketingOnboarding ? null : connectMarketingOnboarding('pinterest')")
              .structure-container-2
                .icon-container(v-if="!imageError") 
                  img.icon(src="@/assets/pinterest-logo.png" alt="Pinterest Ads Icon" @error="handleImageErrorOnboarding")
                .text-container-2
                  .centered-marketing Pinterest Ads
                  .x-platform-text Pull your marketing costs
              .connected-confirm(v-if="pinterestOn") Connected    
            .x-container-2(@click="loadingMarketingOnboarding ? null : connectMarketingOnboarding('facebook')")
              .structure-container-2
                .icon-container(v-if="!imageError")  
                  img.icon(src="@/assets/meta_ads.png" alt="Meta Ads Icon" @error="handleImageErrorOnboarding")
                .text-container-2
                  .centered-marketing Meta Ads
                  .x-platform-text Pull your marketing costs
              .connected-confirm(v-if="facebookOn") Connected    
            .x-container-2(@click="loadingMarketingOnboarding ? null : connectMarketingOnboarding('snapchat')")
              .structure-container-2
                .icon-container(v-if="!imageError")  
                  img.icon(src="@/assets/Snapchat_App_Icon.png" alt="Snapchat Ads Icon" @error="handleImageErrorOnboarding")
                .text-container-2
                  .centered-marketing Snapchat Ads
                  .x-platform-text Pull your marketing costs
              .connected-confirm(v-if="snapchatOn") Connected   
            .x-container-2(@click="loadingMarketingOnboarding ? null : connectMarketingOnboarding('google')")
              .structure-container-2
                .icon-container(v-if="!imageError")  
                  img.icon(src="@/assets/google_ads_icon.png" alt="Google Ads Icon" @error="handleImageErrorOnboarding")
                .text-container-2
                  .centered-marketing Google Ads
                  .x-platform-text Pull your marketing costs
              .connected-confirm(v-if="googleOn") Connected   
            .x-container-2(@click="loadingMarketingOnboarding ? null : connectMarketingOnboarding('tiktok')")
              .structure-container-2
                .icon-container(v-if="!imageError")  
                  img.icon(src="@/assets/TikTok.png" alt="Tiktok Ads Icon" @error="handleImageErrorOnboarding")
                .text-container-2
                  .centered-marketing TikTok Ads
                  .x-platform-text Pull your marketing costs
              .connected-confirm(v-if="tiktokOn") Connected 
          .warning-text(v-else) Please use our desktop version to complete the Setup
      .spacing 
      .onboarding-section

        .onboarding-titl-2 Is something unclear or missing?
        .onboarding-title-2 Please contact our support via the chat in the bottom right corner. We are happy to help and build custom features for free.
      .button-container-align
        button.buttonStyle(@click="startXProfit()") Skip
        button.buttonStyle(@click="startXProfit()") Save
  .graphics(v-else)
    button.mobileMenu(v-if="isMobile" @click="toggleSidebar")
      b-icon(icon="list")
    .sidebar(v-if="!isMobile || (isMobile && !isSidebarCollapsed)")
      .center-logo
        xProfit.large(:size="30")
        xProfit.small(:size="15")

      div.label-dropdown-wrapper(v-if="this.$store.state.userdata.meta")
        button.dropdown-button(ref="button" @click="isLabelOpenAccount = !isLabelOpenAccount") {{ userAcc }}
          b-icon(icon="chevron-down" class="thick-chevron") 
        div.label-dropdown-menu-meta(v-if="isLabelOpenAccount", ref="uniqueSet")
          div.dropdown-item(v-for="(option, index) in this.$store.state.userdata.metaAccounts", @click="selectAccount(option)") {{ option.storeName }}
          div.super-small-text(v-if="this.$store.state.userdata.metaAccounts.length < 2") Connect a store under Setup > General
      .horizontalLine
      .router
        div.menu-item(v-for="link in links" :key="link.to" @click="toggleSidebar()")
          router-link.menu-link(:to="link.to" :id="`menu-link-${link.tag}`" @click.native="openLink = link" :style="{ opacity: link.pro ? '0.6' : '1' }")
            component(:is="link.component")
            span.menu-text(style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;") {{ link.text }}
            span.pro-sign(v-if="link.pro") Pro
          transition(v-if="!isMobile" name="slide-fade")
            div.nested-menu(v-if="openLink === link || showSubmenu" class="nested-menu")
              router-link.menu-link(v-for="nestedLink in link.nestedLinks" :key="nestedLink.to" :to="nestedLink.to" :id="`menu-link-${nestedLink.tag}`" :style="{ opacity: link.pro ? '0.4' : '1', marginLeft: '5px'  }")
                span.menu-text(style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;") {{ nestedLink.text }}
                span.pro-sign(v-if="nestedLink.pro") Pro
      .bottom
        b-button.xprofit-small-button(@click="restartTour") Restart Tour
        b-button.xprofit-small-button(v-if="customlogin" @click="logout") logout
        b-button.xprofit-small-button(@click="showHelpForm = !showHelpForm") Help
        b-button.xprofit-small-button(@click="pricing") Pricing
    .content()
      .loaderBanner(v-if="initialFetchingData && showSubmenu == false")
        LocalLoader(medium :text="loadigntext" align="row")
      template(v-else)
        slot
        .bottomText 
          .connected(v-if="!initialFetchingData") Connected with: '{{$store.getters["user/getUser"]?.uid}}'
        .invisbleBlocker
  xProfitTour(ref="tour")
  ChooseAdAccountOnboarding(ref="ChooseAdAccountOnboarding", @adAccountSelected="handleAdAccountSelectedOnboarding" @closedAdAccountOnboarding="closeAdAccountOnboarding")
  ReconnectAdAccountOnboarding(ref="ReconnectAdAccountOnboarding", @reconnect="startRecconectOnboarding")
  //- .chat-bubble(@click="showHelpForm = !showHelpForm")
  //-   b-icon(icon="chat-square-text-fill")
  .overlay(v-if="showHelpForm")
    .help-form
      LocalLoader(v-if="loadingHelp")
      .rest(v-else)
        h2 {{headding}}.
        span {{text}}
        button.cancel(v-if="sendHelp" @click="showHelpForm = false") Close
        label(v-if="!sendHelp") Email
        input(v-if="!sendHelp" type="email" v-model="helpFormEmail")
        label(v-if="!sendHelp") Describe the issue
        textarea(v-if="!sendHelp" v-model="helpFormIssue")
        .buttons(v-if="!sendHelp")
          button.cancel(@click="showHelpForm = false") Cancel
          button.send(@click="sendHelpRequest") Send


    //- Assuming the elements are inside a parent element


  </template>
  
<script>
import pageError from "../components/pageError.vue"
import xProfit from "../components/xProfitLogoComponent.vue"
import xProfitTour from "../components/XProfitTourComponent.vue"
import firebase from 'firebase/compat/app';
import ProductsIcon from "@/assets/cubes-icon.vue";
import SettingsIcon from "@/assets/settings-icon.vue";
import BarChartIcon from "@/assets/bar-chart-icon.vue";
import FixedPaymentIcon from "@/assets/fixed-payments-icon.vue";
import MultiIcon from "@/assets/multi-store-icon.vue";
import { mapActions } from 'vuex'
import ChooseAdAccountOnboarding from '@/components/modals/ChooseAdAccountOnboarding.vue';
import ReconnectAdAccountOnboarding from '@/components/modals/ReconnectMarketingOnboarding.vue';

export default {
  components: {
    pageError,
    xProfit,
    xProfitTour,
    BarChartIcon,
    ProductsIcon,
    SettingsIcon,
    FixedPaymentIcon,
    MultiIcon,
    ChooseAdAccountOnboarding,
    ReconnectAdAccountOnboarding
  },
  data() {
    return {
      openLink: null,
      acceptTerms: true,
      initialFetchingData: true,
      headding: "Contact us",
      text: "Please describe in detail what issues you encountered.",
      loadingHelp: false,
      firstSignup: false,
      onboadingLvl: 0,
      profitEmail: "",
      sendHelp: false,
      customlogin: false,
      showHelpForm: false,
      helpFormEmail: '',
      helpFormIssue: '',
      pro: true,
      accounts: [],
      selectedAccount: "This Account",
      isLabelOpenAccount: false,
      screenWidth: window.innerWidth,
      loadigntext: " Preparing your numbers...",
      showSubmenu: false,
      googleOn: false,
      pinterestOn: false,
      facebookOn: false,
      snapchatOn: false,
      tiktokOn: false,
      currencySymbol: "",
      prePaymentProvider: [],
      isSidebarCollapsed: true,
      defaultMargin: "30.00 %",
      loadingMarketingOnboarding: false,
      currentMarketingOnboarding: "",
      imageError: false,
      marketingImportStart: false,
      links: [
        {
          to: "/",
          icon: "cash-coin",
          component: BarChartIcon,
          text: "Dashboard",
          tag: "dashboard",
          pro: false,
        },
        {
          to: "/analytics",
          icon: "cash-coin",
          component: ProductsIcon,
          text: "Product Analytics",
          tag: "analytics",
          pro: false,
        },
        {
          to: "/settings/general",
          icon: "gear",
          component: SettingsIcon,
          text: "Setup",
          tag: "general",
          pro: false,
          nestedLinks: [
            {
              to: "/settings/general",
              text: "General",
              tag: "general",
              pro: false,
            },
            {
              to: "/settings/productcosts",
              text: "Product Costs",
              tag: "productcosts",
              pro: false,
            },
            // {
            //   to: "/settings/marketing",
            //   text: "Marketing",
            //   tag: "marketing",
            //   pro: false,
            // },
            {
              to: "/fixedpayments",
              text: "Operating Expenses",
              tag: "operatingexpenses",
              pro: false,
            }
          ]
        }
      ],
    }
  },
  computed: {
    user() {
      this.$store.getters['user']
    },
    userAcc() {
      if (this.isMobile) {
        return "S"
      } else {
        const uidToFind = this.$store.getters["user/getUser"]?.uid

        // Assuming your array is called 'yourArray'
        const foundObject = this.$store.state.userdata.metaAccounts.find(obj => obj.url === uidToFind);

        return foundObject?.storeName ?? ""
      }

    },
    isDev() {
      return process.env.NODE_ENV == "development"
    },
    products() {
      return this.$store.state.shopify.products;
    },
    isSettingsRoute() {
      return this.$route.path.startsWith("/settings");
    },
    isMobile() {

      return this.screenWidth <= 825; // Adjust the threshold as needed
    },
  },
  created() {
    // Assuming you are getting the current route path here
    const currentRoute = this.$route.path;
    // Logic to determine if the current route is inside the setup submenu
    // For example, check if the currentRoute contains the path to the setup submenu
    if (currentRoute.includes("/settings")) {
      this.showSubmenu = true;
    } else {
      this.showSubmenu = false;
    }

  },
  watch: {
    '$route'(to, from) {
      // Assuming you are getting the current route path here
      const currentRoute = to.path;
      // Logic to determine if the current route is inside the setup submenu
      // For example, check if the currentRoute contains the path to the setup submenu
      if (currentRoute.includes("/settings")) {
        this.showSubmenu = true;
        console.log("t", this.showSubmenu)
      } else {
        this.showSubmenu = false; // Set showSubmenu to false for other routes
      }
    }
  },
  methods: {
    ...mapActions(["AddPageError"]),
    logout() {
      firebase.auth().signOut().then(() => {
        localStorage.removeItem("customlogin")
        this.$router.replace('admincenter');
      });
    },
    toggleSidebar() {
      console.log("toggle")
      this.isSidebarCollapsed = !this.isSidebarCollapsed
    },
    async selectAccount(accountObject) {
      console.log("selected Account", accountObject)

      let account = accountObject.url

      if (account != this.$store.getters["user/getUser"]?.uid) {


        this.loadigntext = "Switching store..."
        this.initialFetchingData = true;

        this.selectedAccount = account;
        this.isLabelOpenAccount = false;
        const data = await this.$store.dispatch("userdata/ChangeUser", account)

        if (data != null && data.authToken != null) {
          // In this case we succeeded and we can save these.
          this.$cookie.set('authToken', data.authToken)

          firebase.auth().signInWithCustomToken(data.authToken).then(x => {
            // this.$router.push("/?reloadShopifyData=true")

            window.location.reload();
          }).catch(e => {
            this.AddPageError({ msg: "We could not connect this shop to the app: " + e, tag: "error" })
          })
          this.loading = false;
        } else {
          this.AddPageError({ msg: "We could not connect your shop to this app for an unknown reason. Please contact our support! ", tag: "error" })
          this.loading = false;
        }
      } else {
        this.isLabelOpenAccount = false;
      }

    },
    restartTour() {
      this.$refs.tour.startTour();
    },
    pricing() {
      if (this.initialFetchingData == false) {
        this.$router.push('/pricing')
      } else {
        this.AddPageError({ msg: "Please wait until the initial calculations are completed", tag: "error" })
      }
    },
    updateEmail(event) {

      this.profitEmail = event.target.value;

    },
    setprofitEmail() {

      let userDataG = { incomeTax: "0", email: this.profitEmail, incomeTaxNumber: 0 }
      this.$store.dispatch("user/SetUserDataGeneral", userDataG)
      this.$store.dispatch("userdata/SetUserDataGeneral", userDataG)

    },

    startXProfit() {
      if (this.onboadingLvl == 0) {
        if (this.profitEmail != "" && this.acceptTerms == true) {
          this.onboadingLvl = 1;
          try {
            this.$store.dispatch("userdata/activateWebhookDeinstall", {})
          } catch (e) {
            console.log("failed to set webhook")
          }
        } else {
          this.AddPageError({ msg: "Please accept our Terms & Conditions and enter a valid email address.", tag: "error" })
        }
      } else if (this.onboadingLvl == 1) {
        this.startDataTransfer()
        this.onboadingLvl = 2;


      } else {
        this.firstSignup = false;
        if (this.marketingImportStart) {
          this.startMarketingTransfer()
        } else {
         this.marketingImportStart = true
        }
        console.log("start tour from onboarding")
        this.$refs.tour.startTour();
      }


    },
    async startMarketingTransfer() {
      try {
        console.log("Start Marketing transfer")

        await this.$store.dispatch("shopify/FetchFirstMarketing");
     
        this.initialFetchingData = false;
      } catch (e) {
        this.initialFetchingData = false;
        console.log("error fetching first marketing import", e);
      }
    },

    async startDataTransfer() {
      try {
        console.log("Start data transfer")
        var initialConfirmData = { defaultMargin: this.defaultMargin, prePaymentProvider: this.prePaymentProvider, currencySymbol: this.currencySymbol }
        await this.$store.dispatch("shopify/FetchNewOrdersInitial", initialConfirmData);
        const lastLoginRenwew = await this.$store.dispatch("userdata/GetLastLogin");

        if (this.marketingImportStart) {
         this.startMarketingTransfer()
        } else {
          this.marketingImportStart = true
         
        }

       
      } catch (e) {
        this.initialFetchingData = false;
        console.log(e);
      }
    },
    async sendHelpRequest() {


      const data = {
        email: this.helpFormEmail,
        issue: this.helpFormIssue,
      };

      this.loadingHelp = true

      let send = await this.$store.dispatch("userdata/SendHelpRequest", data)

      if (send == "success") {
        this.headding = "We got your message!"
        this.text = "Please check your Spam folder, We will answer in 24 - 48 hours"
        this.sendHelp = true
      } else {
        this.headding = "Ooops!"
        this.text = "Something went wrong"
        this.sendHelp = true
      }

      this.helpFormEmail = '';
      this.helpFormIssue = '';

      this.loadingHelp = false
    },

    formatValuePercentage(index) {

      var row = this.prePaymentProvider[index]
      var value = row.percent

      var newValue = 0;

      if (value !== '') {
        const parsedValue = parseFloat(value.replace('%', ''));
        if (!isNaN(parsedValue)) {
          if (parsedValue <= 100) {
            newValue = parseFloat(parsedValue.toFixed(2))
          } else {
            newValue = 100
          }

        } else {
          newValue = 0
        }
      }

      var percValue = newValue.toFixed(2) + " %"
      console.log("per", percValue)
      this.prePaymentProvider[index].percent = percValue
    },

    updatePercent(event, index) {
      this.prePaymentProvider[index].percent = event.target.value;
    },

    formatValuePercentage(index) {

      var row = this.prePaymentProvider[index]
      var value = row.percent

      var newValue = 0;

      if (value !== '') {
        const parsedValue = parseFloat(value.replace('%', ''));
        if (!isNaN(parsedValue)) {
          if (parsedValue <= 100) {
            newValue = parseFloat(parsedValue.toFixed(2))
          } else {
            newValue = 100
          }

        } else {
          newValue = 0
        }
      }

      var percValue = newValue.toFixed(2) + " %"
      console.log("per", percValue)
      this.prePaymentProvider[index].percent = percValue
    },

    updatePercent(event, index) {
      this.prePaymentProvider[index].percent = event.target.value;
    },

    formatDefaultMargin() {


      var value = this.defaultMargin

      var newValue = 0;

      if (value !== '') {
        const parsedValue = parseFloat(value.replace("%", ''));
        if (!isNaN(parsedValue)) {
          if (parsedValue <= 100) {
            newValue = parseFloat(parsedValue.toFixed(2))
          } else {
            newValue = 100
          }

        } else {
          newValue = 0
        }
      }

      var fixValue = newValue.toFixed(2) + " %"
      this.defaultMargin = fixValue
    },

    updateDefaultMargin(event) {
      this.defaultMargin = event.target.value;
    },
    handleImageErrorOnboarding() {
      this.imageError = true;
    },
    connectMarketingOnboarding(adsplattform) {
      this.loadingMarketingOnboarding = true
      if (adsplattform == "test") {
        console.log("f")
        this.$store.dispatch("userdata/TestPinterest")
      }

      if (adsplattform == "pinterest") {
        //open pinterest connection
        this.currentMarketingOnboarding = "pinterest"
        console.log("trigger funciton")
        if (this.pinterestOn == false) {
          console.log("open pinterest")
          this.$store.dispatch("userdata/ConnectPinterest", "Onboarding")
            .then(data => {
              console.log("Recieved Ad Accounts", data)
              // Check if user has multiple ad accounts. if yes he needs to choose
              if (data.length == 1) {
                this.pinterestOn = true
                this.loadingMarketingOnboarding = false
                this.$store.dispatch("userdata/SetreloadData", true);
              } else if (data.length > 1) {
                this.selectAdAccountOnboarding(data, "pinterest")
                // open module to choose Account
              } else {
                console.log("trigger failed")
                this.AddPageError({ msg: "Pinterest Connection Failed - Please Retry Again", tag: "error" });
                this.loadingMarketingOnboarding = false
              }
            }).catch(e => {
              this.AddPageError({ msg: "Requesting Pinterest failed", tag: "error" });
              this.loadingMarketingOnboarding = false
            });
        } else {
          console.log("trigger launch")
          this.$refs.ReconnectAdAccountOnboarding.launchModal(adsplattform);
          this.loadingMarketingOnboarding = false
        }

      } else if (adsplattform == "facebook") {
        this.currentMarketingOnboarding = "facebook"
        if (this.facebookOn == false) {
          console.log("open facebook")
          this.$store.dispatch("userdata/ConnectFacebook")
            .then(data => {
              console.log("Recieved Ad Accounts", data)
              // Check if user has multiple ad accounts. if yes he needs to choose
              this.selectAdAccountOnboarding(data.data, "facebook")
              // open module to choose Account

            }).catch(e => {
              this.AddPageError({ msg: "Requesting Facebook failed", tag: "error" });
              this.loadingMarketingOnboarding = false
            });
        } else {
          this.$refs.ReconnectAdAccountOnboarding.launchModal(adsplattform);
          // this.AddPageError({ msg: "Facebook is already connected", tag: "error" });
          this.loadingMarketingOnboarding = false
        }

      } else if (adsplattform == "snapchat") {
        this.currentMarketingOnboarding = "snapchat"
        if (this.snapchatOn == false) {
          console.log("open snapchat")
          this.$store.dispatch("userdata/ConnectSnapchat")
            .then(data => {
              console.log("Recieved Ad Accounts", data)
              // Check if user has multiple ad accounts. if yes he needs to choose
              this.selectAdAccountOnboarding(data, "snapchat")
              // open module to choose Account

            }).catch(e => {
              this.AddPageError({ msg: "Requesting Snpachat failed", tag: "error" });
              this.loadingMarketingOnboarding = false
            });
        } else {
          this.$refs.ReconnectAdAccountOnboarding.launchModal(adsplattform);
          this.loadingMarketingOnboarding = false
        }

      } else if (adsplattform == "google") {
        this.currentMarketingOnboarding = "google"
        if (this.googleOn == false) {
          console.log("open google first call")
          this.$store.dispatch("userdata/ConnectGoogle")
            .then(data => {
              console.log("Recieved Ad Accounts", data)
              // Check if user has multiple ad accounts. if yes he needs to choose
              this.selectAdAccountOnboarding(data, "google")
              // open module to choose Account

            }).catch(e => {
              this.AddPageError({ msg: "Requesting Google failed", tag: "error" });
              this.loadingMarketingOnboarding = false
            });

        } else {
          this.$refs.ReconnectAdAccountOnboarding.launchModal(adsplattform);
          this.loadingMarketingOnboarding = false
        }
      } else if (adsplattform == "tiktok") {
        this.currentMarketingOnboarding = "tiktok"
        if (this.tiktokOn == false) {
          console.log("open tiktok first call")
          this.$store.dispatch("userdata/ConnectTiktok")
            .then(data => {
              console.log("Recieved Ad Accounts", data)
              // Check if user has multiple ad accounts. if yes he needs to choose
              this.selectAdAccountOnboarding(data, "tiktok")
              // open module to choose Account

            }).catch(e => {

              this.loadingMarketingOnboarding = false
            });

        } else {
          this.AddPageError({ msg: "Tiktok is already connected", tag: "error" });
          this.$refs.ReconnectAdAccountOnboarding.launchModal(adsplattform);
          this.loadingMarketingOnboarding = false
        }
      }
      this.$store.dispatch("userdata/SetIncompleteData", false)

    },
    handleAdAccountSelectedOnboarding(selected) {
      // Handle the selected ad account and put the pinterest connection to true
      if (selected) {
        console.log("selected")
        if (this.currentMarketingOnboarding == "pinterest") {
          this.pinterestOn = true;
        } else if (this.currentMarketingOnboarding == "facebook") {
          this.facebookOn = true;
        } else if (this.currentMarketingOnboarding == "snapchat") {
          this.snapchatOn = true;
        } else if (this.currentMarketingOnboarding == "google") {
          this.googleOn = true
        } else if (this.currentMarketingOnboarding == "tiktok") {
          this.tiktokOn = true
        }
      }
      this.loadingMarketingOnboarding = false
    },
    closeAdAccountOnboarding() {
      this.loadingMarketingOnboarding = false
    },
    startRecconectOnboarding(selected) {
      // Handle the selected ad account and put the pinterest connection to true
      if (selected) {
        console.log("selected auth")


        if (this.currentMarketingOnboarding == "pinterest") {
          this.pinterestOn = false;
        } else if (this.currentMarketingOnboarding == "facebook") {
          this.facebookOn = false;
        } else if (this.currentMarketingOnboarding == "snapchat") {
          this.snapchatOn = false;
        } else if (this.currentMarketingOnboarding == "google") {
          this.googleOn = false;
        } else if (this.currentMarketingOnboarding == "tiktok") {
          this.tiktokOn = false;
        }

        this.connectMarketingOnboarding(this.currentMarketingOnboarding)
      }
      this.loadingMarketingOnboarding = false
    },
    selectAdAccountOnboarding(data, platform) {
      this.$refs.ChooseAdAccountOnboarding.launchModal(data, platform, "Onboarding");
      this.$store.dispatch("userdata/SetreloadData", true);
    },
    setMultiStore() {
      this.links = [
        {
          to: "/multi-store-dashboard",
          icon: "cash-coin",
          component: MultiIcon,
          text: "Multi-Store Insights",
          tag: "multi-store-dashboard",
          pro: false,
        },
        {
          to: "/",
          icon: "cash-coin",
          component: BarChartIcon,
          text: "Dashboard",
          tag: "dashboard",
          pro: false,
        },
        {
          to: "/analytics",
          icon: "cash-coin",
          component: ProductsIcon,
          text: "Product Analytics",
          tag: "analytics",
          pro: false,
        },
        {
          to: "/settings/general",
          icon: "gear",
          component: SettingsIcon,
          text: "Setup",
          tag: "general",
          pro: false,
          nestedLinks: [
            {
              to: "/settings/general",
              text: "General",
              tag: "general",
              pro: false,
            },
            {
              to: "/settings/productcosts",
              text: "Product Costs",
              tag: "productcosts",
              pro: false,
            },
            // {
            //   to: "/settings/marketing",
            //   text: "Marketing",
            //   tag: "marketing",
            //   pro: false,
            // },
            {
              to: "/fixedpayments",
              text: "Operating Expenses",
              tag: "operatingexpenses",
              pro: false,
            }
          ]
        }

      ]
    },
    setDemo() {
      this.links = [
        {
          to: "/",
          icon: "cash-coin",
          component: BarChartIcon,
          text: "Dashboard",
          tag: "dashboard",
          pro: false,
        },
        {
          to: "/analytics",
          icon: "cash-coin",
          component: ProductsIcon,
          text: "Product Analytics",
          tag: "analytics",
          pro: false,
        },
        {
          to: "/settings/general",
          icon: "gear",
          component: SettingsIcon,
          text: "Setup",
          tag: "general",
          pro: false,
          nestedLinks: [
            {
              to: "/settings/general",
              text: "General",
              tag: "general",
              pro: false,
            },
            {
              to: "/settings/productcosts",
              text: "Product Costs",
              tag: "productcosts",
              pro: false,
            },
            // {
            //   to: "/settings/marketing",
            //   text: "Marketing",
            //   tag: "marketing",
            //   pro: false,
            // },
            {
              to: "/pricing",
              text: "Operating Expenses",
              tag: "operatingexpenses",
              pro: true,
            }
          ]
        }
      ]
      // this.$router.push('/analytics').catch(() => { });

    }
  },
  async mounted() {
    try {



      this.customlogin = localStorage.getItem("customlogin") ?? false;
      const lastLogin = await this.$store.dispatch("userdata/GetLastLogin");

      if (lastLogin == null) {
        this.loadigntext = " Initial profit calculations... This process can take up to 2 minutes.<br><br><em>If you have more than 3000 orders per month, this process may take even longer.</em>";
        this.firstSignup = true;
        this.initialFetchingData = true;

        try {


          var firstInfo = await this.$store.dispatch("shopify/FetchFirstInfo");
          console.log("first info", firstInfo)
          this.prePaymentProvider = firstInfo.prePaymentProvider
          this.currencySymbol = firstInfo.currencySymbol

          console.log("first info", firstInfo)

        } catch (e) {

          console.log("failed to get first info", e);
        }
      }

      this.loadigntext = " Preparing your numbers..."
      if (!this.$store.state.userdata.pro) {
        this.setDemo()
      }

      if (this.$store.state.userdata.meta) {
        this.setMultiStore()
        console.log("mulit store", this.$store.state.userdata.metaAccounts)
      }

      
    } catch (e) {
      console.log(e);
      this.initialFetchingData = false;
    } finally {
      this.initialFetchingData = false;

      // if (this.products?.length == 0 ?? true) {
      //   await this.$store.dispatch("shopify/FetchProductsFromDatabase");
      // }

    }
  }

}
</script>
  
<style scoped lang="scss">
$sidebarWidth: 230px;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-form {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rest {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.help-form h2 {
  text-align: center;
}

.help-form label,
.help-form input,
.help-form textarea {
  width: 100%;
}

.help-form .buttons {
  display: flex;
  justify-content: space-between;
}

.help-form button {
  background-color: $text-color;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.help-form button:hover {
  background-color: $text-color;
}



.authenticated {
  width: 100%;
  height: 100%;

  .beta-banner {
    position: fixed;
    top: 30px;
    right: -50px;
    width: 200px;
    height: 30px;
    background-color: $xprofit;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transform: rotate(45deg);
    z-index: 99;
  }

  .pro-sign {
    color: $xprofit
  }

  >.graphics {
    width: 100%;
    height: 100%;
    display: flex;

    >.sidebar {
      width: $sidebarWidth;
      background-color: $background-white;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
      display: block;
      height: 100vh;
      position: sticky;
      padding: 0 15px;
      z-index: 9;
      top: 0;


      >.center-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;

        >.large {
          display: block;
        }

        >.small {
          display: none;
        }
      }

      >.horizontalLine {
        width: 100%;
        border-bottom: 3px solid rgba(244, 244, 246, 0.99);
      }

      .router {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: auto;


      }

      >.bottom {
        margin-top: auto;
        display: block;
        text-align: center;
        font-size: 10px;
        bottom: $layoutDefaultMargin ;
        padding: 0 $layoutDefaultMargin;
        left: 0;
        position: absolute;
      }
    }

    >.content {
      width: calc(100% - $sidebarWidth - 2 * $layoutDefaultMargin);
      margin-left: $layoutDefaultMargin;
      margin-bottom: $layoutDefaultMargin;
      display: block;
      position: relative;

      >.bottomText {
        position: absolute;
        bottom: -15px;
        right: 0px;
        font-size: 12px;
      }

      >.loaderBanner {
        position: absolute;
        width: 100%;
        height: 100%;
        color: $text-color;
        border-radius: 5px;
        top: 0;
        right: 0;
        font-size: 12px;
        margin-bottom: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      >.invisbleBlocker {
        width: 1px;
        height: 1px;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 20%;
        right: 50%;
        background-color: rgba(black, 0.4)
      }
    }


  }

  .dropdown-item {
    cursor: pointer;
  }

  .menu-link {
    width: 100%;
    color: $text-color;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 5px;
    border-radius: 6px;
    margin: 10px 0 0px;
  

    &:hover,
    &.router-link-exact-active {
      background-color: $background-grey;
      color: $text-color;
    }

    >span {
      margin-left: 10px;
    }
  }

  .nested-menu .menu-item {
    background-color: #3d3d3d;
    /* Different background color for nested items */
    margin-top: 0px;
    /* Greater indentation for nested items */
  }

  .nested-menu .menu-text {
    color: $text-color;
    opacity: 0.7;
  }

  .nested-menu .menu-link.router-link-exact-active .menu-text {
    color: $text-color;
    opacity: 1;
  }

  .nested-menu .menu-link {

    height: 20px;
    padding-left: 20px;

    &:hover,
    &.router-link-exact-active {
      background-color: transparent;
      color: $text-color;
      opacity: 1;
    }

  }

  .nested-menu .menu-text {
    color: $text-color;
    opacity: 0.7;

    &:hover,
    &.router-link-exact-active {
      background-color: transparent;
      color: $text-color;
      opacity: 1;
    }

    /* Different text color for nested items */
  }
}

.custom-default-page-block {
  /* Set the fixed height and width for the DefaultPageBlock itself */
  width: 600px;
  position: relative;
  height: 500px;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  /* Add this line to center items horizontally */
}

.centered-block {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Set the height and width of the DefaultPageBlock's parent container here */
  height: 100vh;
  /* You can adjust this value as needed */
  width: 100%;
  /* You*/
}

.small-text {
  font-size: 16px;
  color: $text-color;
  opacity: 0.6;
  text-align: center;

}

.big-text {
  font-size: 24px;
  color: $text-color;

}

.big-text-fade {
  font-size: 24px;
  color: $text-color;
  opacity: 0.6;
  text-align: center;


}

.spacing {
  height: 50px;
}

.styled-text-field {
  border: 1px solid rgba(29, 39, 82, 0.6);
  /* Add a border to the text field */
  padding: 5px;
  /* Add some padding to the text field */
  border-radius: 5px;
  /* Round the corners of the text field */
  width: 250px;
  font-size: 16;
  font-weight: $normal-Font-Weight;
  color: $text-color;
  margin-bottom: 25px;
  text-align: center;
  /* Set a fixed width for the text field */

}

.styled-text-field::placeholder {
  color: $text-color;
  /* Change this to the desired color for the placeholder */
  text-align: center;
  /* Center the placeholder text */
  opacity: 0.6;
}


.buttonStyle {

  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 30px;
  width: 220px;
  background-color: $text-color;
  color: white;
  border-radius: 5px;
  border: none;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.nested-menu {
  display: flex;
  flex-direction: column;
}

.chat-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: white;
  color: $text-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.super-small-text {
  font-size: 12px;
  color: $text-color;
  opacity: 0.6;
  text-align: left;
}



.label-dropdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.label-dropdown-menu-meta {
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 10px;
  min-width: 200px;
}

.dropdown-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container-Chart {
  display: flex;
}

.dropdown-button {
  border: none;
  background-color: $background-grey;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 20px;
  color: $text-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;

}


.dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 989;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 10px;
  min-width: 200px;
}

.dropdown-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.dropdown-value {
  margin-left: 5px;
  color: #777;
}

.thick-chevron {
  stroke-width: 20;
  font-weight: 900;
  /* Set this to whatever font weight you want */
  margin-left: 5px;
}




$mobileSideBarWidth : 70px;
$mobileLayoutMargin: 10px;

@media (max-width: 825px) {
  .authenticated>.graphics {

    .sidebar {
      width: $mobileSideBarWidth;
      transition: width 1s ease;

      >.center-logo {
        >.large {
          display: none;
        }

        >.small {
          display: block;
        }
      }

      .router {
        >.menu-link {
          padding: 5px 0px 5px 10px;
          height: 40px;

          >span {
            position: absolute;
            opacity: 0;
            transition: opacity 1s ease;
            min-width: 300px;
            left: 50px;
          }
        }
      }

      >.bottom {
        display: none;
      }
    }

    >.content {
      width: 100%;
      transition-property: width, margin;
      transition-duration: 1s;
      transition-timing-function: ease;
      margin: $mobileLayoutMargin;

      >.bottomText {
        bottom: -10px;
      }
    }
  }

  .chat-bubble {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    color: $text-color;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
  }

  .entry-s {
  /* Add space between rows */
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;


}

.entry-name {

border: none;
background-color: white;
min-width: 240px;
max-width: 240px;
font-size: 14px;
padding-left: 10px;
padding-right: 10px;
padding-top: 5px;
padding-bottom: 5px;
margin-top: 5px;
line-height: 20px;
color: $text-color;
display: flex;
align-items: center;
border-radius: 20px;
}

.entry-name-2 {
  border: none;
  background-color: white;
  min-width: 240px;
  max-width: 240px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 20px;
  color: $text-color;
  display: flex;
  align-items: center;
  margin-top: 5px;
  border-radius: 20px;
}

.styled-text-field-onboarding {
  border: 0px;
  /* Add a border to the text field */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
  /* Add some padding to the text field */
  border-radius: 20px;
  /* Round the corners of the text field */
  min-width: 120px;
  max-width: 120px;
  font-size: 16;
  line-height: 20px;
  font-weight: $normal-Font-Weight;
  color: $text-color;
  text-align: center;
}


}



.chat-bubble:hover {
  background-color: $text-color;
  color: white;
  transform: scale(1.1);
}

.mobileMenu {
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: white;
  color: $text-color;
  border-radius: 30%;
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: white;
    color: $text-color;
    transform: scale(1.1); // You can adjust the scale for the desired effect
  }
}

.custom-default-page-block-2 {
  /* Set the fixed height and width for the DefaultPageBlock itself */
  width: 900px;
  position: relative;
  min-height: 700px;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  display: flex;
  padding: 15px;
  flex-direction: column;
  /* Add this line to center items horizontally */
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: space-between;

}

.progress-bar-fill {
  background-color: $text-color;
  width: 49%;
  height: 10px;
  border-radius: 5px;
}

.progress-bar-unfill {
  background-color: $background-grey;
  width: 49%;
  height: 10px;
  border-radius: 5px;

}

.head-space-onboarding {
  margin-top: 40px;
  margin-bottom: 20px;
}

.head-line-onboarding {
  font-size: 24px;
  color: $text-color;
  opacity: 0.6;
  text-align: left;
}

.onboarding-section {
  width: 100%;
  min-height: 60px;
  background-color: $background-grey;
  border-radius: 10px;
  padding: 10px;

}

.onboarding-title {
  font-size: 16px;
  color: $text-color;
  text-align: left;
  width: 120px;

}

.onboarding-title-2 {
  font-size: 16px;
  color: $text-color;
  text-align: left;


}

.margin-info-container {
  margin-right: auto;
  /* Add some margin between the containers */
  display: flex;
  align-items: left;
  justify-content: flex-start;
  max-height: 33px;
  max-width: 220px;

}

.wrapper {
  width: 20px !important;
  height: 20px !important;
}

.entry-s {
  /* Add space between rows */
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  


}

.entry-name {

  border: none;
  background-color: white;
  min-width: 240px;
  max-width: 240px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 20px;
  color: $text-color;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.entry-name-2 {
  border: none;
  background-color: white;
  min-width: 240px;
  max-width: 240px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 20px;
  color: $text-color;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.styled-text-field-onboarding {
  border: 0px;
  /* Add a border to the text field */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* Add some padding to the text field */
  border-radius: 20px;
  /* Round the corners of the text field */
  min-width: 120px;
  max-width: 120px;
  font-size: 16;
  line-height: 20px;
  font-weight: $normal-Font-Weight;
  color: $text-color;
  text-align: center;
}

.small-text-2 {
  font-size: 16px;
  color: $text-color;
  opacity: 0.6;
  text-align: center;


}

.button-container-align {
  margin-top: auto;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  flex-direction: row;

}

.warning-text {

  color: $danger;
  border: none;
  font-size: $small-Font-Size;
  padding: 5px 10px;
  margin-left: 10px;
}

.x-pro {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.x-container-2 {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: white;
  border: 5px solid $background-grey;
  /* Set border properties */
  height: 100px;
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
}

.structure-container-2 {
  display: flex;
  /* Use Flexbox to arrange elements in a column */
  /* Align items vertically in a column */
  align-items: center;
  /* Center items horizontally */
}

.x-platform-text {
  opacity: 0.6;
}

.icon-container {
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  margin-left: 5px;
  /* Adjust the margin as needed to create space between the text and the icon */
}

.icon {
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
}

.text-container-2 {
  margin-left: 10px;
  text-align: left;
}

.centered-marketing {

  font-size: $big-Font-Size;
  font-weight: $medium-Font-Weight;
  /* Set the desired text color for centered-marketing */
  width: 100%;

}

.connected-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $small-Font-Size;
  font-weight: $normal-Font-Weight;
  /* Set the desired text color for connected-confirm */
  color: $success;
  width: 100%;


}

.general-container {
  margin-top: 10px;
}
</style>