import { render, staticRenderFns } from "./DefaultPageComponent.vue?vue&type=template&id=1fd38302&scoped=true&lang=pug&"
import script from "./DefaultPageComponent.vue?vue&type=script&lang=js&"
export * from "./DefaultPageComponent.vue?vue&type=script&lang=js&"
import style0 from "./DefaultPageComponent.vue?vue&type=style&index=0&id=1fd38302&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd38302",
  null
  
)

export default component.exports