import axios from "axios";

export default {
    namespaced: true,
    state: {
        shopify_store: null,
        shopify_access_code: null,
        api_version: "2023-10",
    },
    getters: {
      apiUrl(state) {
        //return "http://127.0.0.1:5001/arthur-5a26e/us-central1";
        return process.env.VUE_APP_FUNCTIONS_URL
      } 
    },
    mutations: {
      apiInitiated(state, body){
        state.shopify_access_code = body.access_code;
        state.shopify_store = body.shop;
      }
    },
    actions: {
        apiGet(context, body) {
          var options = getOptions(context, body.path, 'GET', body.headers, undefined, {});
          return executeRequest(options);
        },
        apiPost(context, body) {
          var options = getOptions(context, body.path, 'POST', body.headers, body.body, {});
          return executeRequest(options)
        },
        apiGetWithToken(context, body) {
          var options = getTokenOptions(context, body.path, 'GET', body.headers, undefined, {});
          return executeRequest(options)
        },
        apiPostWithToken(context, body) {
          var options = getTokenOptions(context, body.path, 'POST', body.headers, body.body, {});
          return executeRequest(options)
        },

        initiateApi({commit}, body){
          commit("apiInitiated", body)
        }
    }
};

function executeRequest(options) {
  return new Promise((resolve, reject) => {
    axios(options)
      .then(data =>
        resolve(data)
      ).catch(error =>
        reject(error)
      )
  })
}

var getOptions = function (context, path, method, headers, data, params) {
  var options = {
    url: context.getters.apiUrl + path,
    method: method,
    headers: {},
  };
  if (headers) {
    options.headers = Object.assign({}, options.headers, headers);
  }
  if (data) {
    options.data = data;
  }

  if (params) {
    options.params = params;
  }

  return options;
}

var getTokenOptions = function (context, path, method, headers, body, params) {
  
  var newparams = {...params, 
    access_token: context.state.shopify_access_code, 
    shopName: context.state.shopify_store
  };

  var options = getOptions(context, path, method, headers, body, newparams);
  return options;
}
