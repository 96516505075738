<template lang="pug">
.page-container
    .header-container
      h1.pageTitle {{title}}
      slot(name="optionalElement")
    slot

</template>

  
<script>
export default {
  props:{
    title: String
  }
}

</script>

<style scoped lang="scss">
.page-container {
  width: 100%;
  position: relative;
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: $medium-Font-Size;
}


</style>